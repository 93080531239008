import { useConfig } from '@getvim/react-app-infra';
import { EhrUpdatableEntities } from '../../types/ehr';

export const useEhrUpdatableEntities = (): EhrUpdatableEntities => {
  const { adapterConfig } = useConfig();

  return {
    encounter: {
      diagnoses:
        !adapterConfig?.unsupportedWritebackFields?.updateEncounter?.includes('addedDiagnoses'),
      procedures: !!adapterConfig?.vimConnectCapabilities?.supportsUpdateProcedure,
    },
  };
};

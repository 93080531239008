import {
  isVimOS,
  useVimOsMigrationAPI,
  VimOsMigrationAPIProvider,
} from '@getvim-core-apps/vim-os-migration-api';
import { VimSDKProvider } from '@getvim/internal-vim-os-sdk/react';
import { ApolloClient, ApolloProvider, NormalizedCacheObject } from '@apollo/client';
import { AnalyticsHover as AnalyticsHoverProvider, ErrorBoundaryWrapper } from '@getvim/atomic-ui';
import { FeatureFlagProvider } from '@getvim/feature-flags-react';
import {
  BaseWidgetAnalyticsEventTypes,
  VimProvider as OldVimProvider,
} from '@getvim/react-app-infra';
import React from 'react';
import ReactDOM from 'react-dom';
import { IntlProvider } from 'react-intl';
import { GraphqlClient } from './api/graphqlClient';
import App from './App';
import { widgetId } from './consts';
import { analyticsClient } from './utils/analytics';
import { featureFlagsConfig } from './utils/featureFlags';
import { eligibilityLogger } from './utils/logger';

const onAppHoverStart = (analytics) => {
  analytics.track(BaseWidgetAnalyticsEventTypes.appHoverStart, {
    app_name: widgetId,
    is_scroll_available: document.body.scrollHeight > document.body.clientHeight,
  });
};

const onAppHoverEnd = (analytics, time) => {
  analytics.track(BaseWidgetAnalyticsEventTypes.appHoverEnd, {
    app_name: widgetId,
    app_hover_opened_for_time: time,
  });
};

const handleRenderError = ({ error }) => {
  eligibilityLogger.error('Client application crashed due to render error', {
    error,
  });
};

const handleHoverStart = (analytics) => () => {
  onAppHoverStart(analytics);
};

const handleHoverEnd = (analytics) => (time) => {
  onAppHoverEnd(analytics, time);
};

const AnalyticsHoverProviderWrapper = ({ children }) => {
  const {
    platformActions: { analytics },
  } = useVimOsMigrationAPI();

  return (
    <AnalyticsHoverProvider
      className="app-hover"
      onHoverAction={handleHoverStart(analytics)}
      onLeaveAction={handleHoverEnd(analytics)}
    >
      {children}
    </AnalyticsHoverProvider>
  );
};

const SdkWrapper = ({ children }) =>
  isVimOS() ? <VimSDKProvider>{children}</VimSDKProvider> : children;

const LegacyRuntimeWrapper = ({ children }) =>
  isVimOS() ? (
    children
  ) : (
    <OldVimProvider applicationId={widgetId} onAccessTokenChanged={GraphqlClient.setAccessToken}>
      {children}
    </OldVimProvider>
  );

const Index = () => {
  return (
    <SdkWrapper>
      <React.StrictMode>
        <IntlProvider locale="en">
          <FeatureFlagProvider {...featureFlagsConfig}>
            <ApolloProvider
              client={
                GraphqlClient.eligibilityBFFClient as unknown as ApolloClient<NormalizedCacheObject>
              }
            >
              <LegacyRuntimeWrapper>
                <VimOsMigrationAPIProvider
                  widgetId={widgetId}
                  runtimeAnalyticsClient={analyticsClient}
                  updateNotificationCount={false}
                  setAccessToken={GraphqlClient.setAccessToken}
                >
                  <ErrorBoundaryWrapper
                    onRenderError={handleRenderError}
                    applicationDisplayName={widgetId}
                  >
                    <AnalyticsHoverProviderWrapper>
                      <App />
                    </AnalyticsHoverProviderWrapper>
                  </ErrorBoundaryWrapper>
                </VimOsMigrationAPIProvider>
              </LegacyRuntimeWrapper>
            </ApolloProvider>
          </FeatureFlagProvider>
        </IntlProvider>
      </React.StrictMode>
    </SdkWrapper>
  );
};

ReactDOM.render(<Index />, document.getElementById('root'));

import {
  useEhrState as useOldEhrState,
  WritebackType,
  useEhrWriteback,
} from '@getvim/react-app-infra';
import { useMemo } from 'react';
import {
  useRuntimeConfig,
  useIsAppOpen,
  useEhrUpdatableEntities,
  useSendEventToHub,
} from './converters';
import { VimOsMigrationAPI, VimOsMigrationAPIInput } from '../types';

export const useRuntimeAPI = ({
  widgetId,
  runtimeAnalyticsClient,
}: VimOsMigrationAPIInput): VimOsMigrationAPI => {
  const ehrState = useOldEhrState();
  const config = useRuntimeConfig();
  const isAppOpen = useIsAppOpen(widgetId);
  const [updateEncounter] = useEhrWriteback(WritebackType.updateEncounter);
  const updatableEntities = useEhrUpdatableEntities();
  const sendHubActions = useSendEventToHub(widgetId);

  return useMemo(() => {
    return {
      ehrState,
      ehrEntities: {
        updatableEntities,
        entitiesActions: {
          updateEncounter,
        },
      },
      platformActions: {
        modifyWidget: sendHubActions.modifyWidget,
        resizeWidget: sendHubActions.resizeWidget,
        selectApp: sendHubActions.selectApp,
        runAction: sendHubActions.runAction,
        isAppOpen,
        analytics: runtimeAnalyticsClient,
      },
      config,
      isVimOs: false,
    };
  }, [
    ehrState,
    runtimeAnalyticsClient,
    isAppOpen,
    config,
    sendHubActions,
    updatableEntities,
    updateEncounter,
  ]);
};

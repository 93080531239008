import { useApi } from '@getvim/internal-vim-os-sdk/react';
import { camelCase } from 'lodash-es';
import { useCallback } from 'react';
import { RunActionCallback } from '../../types/api';

export const useRunAction = (): RunActionCallback => {
  const { ehrActions } = useApi();

  return useCallback(
    async (actionType, payload) => {
      try {
        const actionResultData = await ehrActions[camelCase(actionType)](payload);
        return { data: actionResultData };
      } catch (error) {
        return { error, data: undefined };
      }
    },
    [ehrActions],
  );
};

import { useVimOsMigrationAPI } from '@getvim-core-apps/vim-os-migration-api';
import { AnalyticsHover } from '@getvim/atomic-ui';
import { BaseWidgetAnalyticsEventTypes } from '@getvim/react-app-infra';
import React, { FC, useCallback } from 'react';

import { widgetId } from '../../../consts';
import { GetMemberResult } from '../../../types';
import { DetailsCardType } from '../../../utils/analytics';
import { NoDataAvailable } from '../../atoms';
import { MemberDetailsList, NetworkDetailsList, Network } from '../../organisms';

import './index.less';

export interface EligibilityMainProps {
  eligibility: GetMemberResult | undefined;
}

export const EligibilityMain: FC<EligibilityMainProps> = ({ eligibility }) => {
  const {
    ehrState: { patient },
    platformActions: { analytics },
  } = useVimOsMigrationAPI();

  const { inNetwork, outOfNetwork } = eligibility ?? {};

  const handleItemHover = useCallback(
    (position: number, cardType: DetailsCardType) => {
      const source = patient?.insurance?.insurer;
      const contentSupplierPatientId = source
        ? patient?.contentSupplierInternalPersonIds?.[source]
        : undefined;
      analytics.track(BaseWidgetAnalyticsEventTypes.itemHover, {
        app_name: widgetId,
        position,
        app_source_displayed: eligibility!.dataSource ? [eligibility!.dataSource] : [],
        content_supplier_patient_id: contentSupplierPatientId,
        card_type: cardType,
      });
    },
    [eligibility, patient, analytics],
  );

  return (
    <div className="container">
      {eligibility ? (
        <main>
          <AnalyticsHover
            // eslint-disable-next-line react/jsx-no-bind
            onHoverAction={() => handleItemHover(1, DetailsCardType.MemberDetails)}
          >
            <MemberDetailsList eligibility={eligibility} />
          </AnalyticsHover>
          {inNetwork && (
            <AnalyticsHover
              // eslint-disable-next-line react/jsx-no-bind
              onHoverAction={() => handleItemHover(2, DetailsCardType.InNetworkDeductibles)}
            >
              <NetworkDetailsList
                network={Network.InNetwork}
                deductibleDetails={inNetwork}
                dataSource={eligibility?.dataSource}
              />
            </AnalyticsHover>
          )}
          {outOfNetwork && (
            <AnalyticsHover
              // eslint-disable-next-line react/jsx-no-bind
              onHoverAction={() =>
                handleItemHover(inNetwork ? 3 : 2, DetailsCardType.OutOfNetworkDeductibles)
              }
            >
              <NetworkDetailsList
                network={Network.OutOfNetwork}
                deductibleDetails={outOfNetwork}
                dataSource={eligibility?.dataSource}
              />
            </AnalyticsHover>
          )}
        </main>
      ) : (
        <NoDataAvailable />
      )}
    </div>
  );
};

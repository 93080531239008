import { useLazyQuery } from '@apollo/client';
import { logger } from '@getvim/vim-connect-logger';
import gql from 'graphql-tag';
import { omit } from 'lodash-es';
import { useEffect, useState } from 'react';
import { ConfigUnified } from '../../types';
import { useVimUserMetadata } from '@getvim/internal-vim-os-sdk/react';

const vimOsConfigLogger = logger.scope('useVimOsMigrationAPI');

const GET_APP_CONFIG_QUERY = gql`
  query GetAppConfig {
    getAppConfig {
      user {
        email
      }
      organization {
        id
        name
        theme
      }
      userDataSources {
        dataSource
        namespace
        isInsurer
      }
    }
  }
`;

export const useLazyConfig = (): [() => void, ConfigUnified | undefined] => {
  const { ehrVendor } = useVimUserMetadata();
  const [config, setConfig] = useState<ConfigUnified>();
  const [getAppConfig, { data, loading, error }] = useLazyQuery<{ getAppConfig: ConfigUnified }>(
    GET_APP_CONFIG_QUERY,
  );

  useEffect(() => {
    if (loading || !data) {
      return;
    }

    if (error) {
      vimOsConfigLogger.error('Error fetching app config', { error });
      return;
    }

    const backendConfig = omit(data.getAppConfig, '__typename');

    setConfig({
      ...backendConfig,
      adapterName: ehrVendor,
      contentSources: backendConfig.userDataSources!.map(({ dataSource }) => dataSource),
    });
  }, [data, loading, error]);

  return [getAppConfig, config];
};

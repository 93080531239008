import { ApolloError, useQuery } from '@apollo/client';
import { formatDateString, Patient } from '@getvim/react-app-infra';
import { useEffect, useMemo } from 'react';
import { isNumber } from 'lodash-es';
import { GET_ELIGIBILITY_QUERY } from '../api/getEligibility';
import {
  Deductible,
  DeductibleExpenditures,
  GetMemberResult as Eligibility,
  EligibilityResult,
  NetworkDeductibleDetails,
} from '../types';
import { eligibilityLogger } from '../utils/logger';

export function useEligibility(patient: Patient): Partial<{
  eligibility: Eligibility;
  executionTime?: number;
  loading: boolean;
  error: ApolloError;
}> {
  const { data, error, loading } = useQuery(GET_ELIGIBILITY_QUERY, {
    fetchPolicy: 'no-cache',
    variables: { input: buildQueryInput(patient) },
  });

  const { eligibility, executionTime } = useMemo<{
    eligibility: Eligibility;
    executionTime?: number;
  }>(() => {
    const fullEligibility: Eligibility =
      data?.getEligibility && formatEligibilityResponse(patient, data.getEligibility);

    return { eligibility: fullEligibility, executionTime: data?.executionTime };
  }, [data, patient]);

  useEffect(() => {
    if (!loading) {
      if (error) {
        eligibilityLogger.error('Failed to fetch getEligibility query', { error });
      } else if (data) {
        eligibilityLogger.info('Eligibility fetched successfully', { result: data });
      }
    }
  }, [error, data, loading]);

  return {
    eligibility,
    executionTime,
    error,
    loading,
  };
}

function buildQueryInput(patient: Patient) {
  const { patientId, demographics, insurance } = patient;
  const { firstName, middleName, lastName, dateOfBirth } = demographics;
  const { memberId, insurer } = insurance ?? {};

  return {
    patientDetails: {
      patientId,
      firstName,
      middleName,
      lastName,
      dateOfBirth,
      ...(insurer &&
        memberId && {
          insurance: {
            insurer,
            memberId,
          },
        }),
    },
  };
}

function formatEligibilityResponse(
  patient: Patient,
  rawEligibilty: EligibilityResult,
): Eligibility {
  const plan = rawEligibilty.eligibilityPlan[0];

  const { insuranceDetails, inNetwork, outOfNetwork, coPay, dataSource } = plan;

  const primaryProvider = formatFullName(
    insuranceDetails?.assignedPcpFirstName,
    insuranceDetails?.assignedPcpMiddleName,
    insuranceDetails?.assignedPcpLastName,
  );

  const memberName = formatFullName(
    patient?.demographics.firstName,
    patient?.demographics.middleName,
    patient?.demographics.lastName,
  );

  const [effectiveStartDate, effectiveEndDate] = [
    insuranceDetails?.effectiveStartDate,
    insuranceDetails?.effectiveEndDate,
  ];

  const coverage = formatCoverageField(effectiveStartDate, effectiveEndDate);

  const eligibility: Eligibility = {
    memberName,
    memberId: patient?.insurance?.memberId,
    groupNumber: insuranceDetails?.groupCode as string,
    plan: insuranceDetails?.plan as string,
    dateOfBirth: patient?.demographics?.dateOfBirth,
    coverage,
    primaryProvider,
    referral: insuranceDetails?.referral,
    inNetwork: formatDeductibleSection(inNetwork),
    outOfNetwork: formatDeductibleSection(outOfNetwork),
    coPay,
    dataSource,
  };

  return eligibility;
}

function formatAmount(amount?: number): number | undefined {
  if (isNumber(amount)) return amount;
  return undefined;
}

function formatDeductibleExpenditures(
  deductibleExpenditures?: DeductibleExpenditures,
): DeductibleExpenditures {
  const formattedDeductibleExpenditures: DeductibleExpenditures = {
    currentSpent: formatAmount(deductibleExpenditures?.currentSpent),
    maximumAllowed: formatAmount(deductibleExpenditures?.maximumAllowedSpent),
  };

  return formattedDeductibleExpenditures;
}

function formatDeductibleNetwork(
  deductibleNetwork?: NetworkDeductibleDetails,
): NetworkDeductibleDetails {
  const formattedDeductibleNetwork: NetworkDeductibleDetails = {
    deductible: formatDeductibleExpenditures(deductibleNetwork?.deductible),
    outOfPocket: formatDeductibleExpenditures(deductibleNetwork?.outOfPocket),
  };

  return formattedDeductibleNetwork;
}

function formatDeductibleSection(deductibles?: Deductible): Deductible {
  const formattedDeductibles: Deductible = {
    individualNetworkDeductibleDetails: formatDeductibleNetwork(
      deductibles?.individualNetworkDeductibleDetails,
    ),
    familyNetworkDeductibleDetails: formatDeductibleNetwork(
      deductibles?.familyNetworkDeductibleDetails,
    ),
  };

  return formattedDeductibles;
}

function formatFullName(
  firstName: string | undefined,
  middleName: string | undefined,
  lastName: string | undefined,
): string {
  return [firstName, middleName, lastName].filter(Boolean).join(' ').trim();
}

function formatCoverageField(effectiveStartDate, effectiveEndDate): string {
  if (!effectiveStartDate && !effectiveEndDate) return '';

  const startDate = effectiveStartDate ? formatDateString(effectiveStartDate) : 'unknown';
  const endDate = effectiveEndDate ? formatDateString(effectiveEndDate) : 'unknown';

  return `${startDate} - ${endDate}`;
}

import React, { FC } from 'react';
import dayjs from 'dayjs';
import { Insurer } from '@getvim-os/standard';
import { ColorNameEnum, PoweredByVimFooter, SizeEnum, Text, WeightEnum } from '@getvim/atomic-ui';
import {
  Deductible,
  DeductibleExpenditures,
  EligibilitySections,
  GetMemberResult,
} from '../../types';
import {
  Network,
  calculateDeductibleDetails,
  shouldShowDeductibleSection,
  shouldShowNetworkCard,
} from '../organisms';

import getInsurerLogoURL from '../../utils/getInsurerLogoUrl';
import { formatDateString } from '@getvim/react-app-infra';

import './PdfTemplate.less';

export interface EligibilityPdfProps {
  sectionsToSelect: EligibilitySections;
  eligibility?: Partial<GetMemberResult>;
  insurer?: Insurer;
}

export const EligibilityPdfTemplate: FC<EligibilityPdfProps> = ({
  sectionsToSelect,
  eligibility,
  insurer,
}) => {
  const { inNetwork, outOfNetwork } = eligibility ?? {};

  const currentDate = new Date().toLocaleString('en-US', {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
  });

  return (
    <>
      {/* main title */}
      <Text
        text="Eligibility Report"
        size={SizeEnum['24px']}
        weight={WeightEnum.bold}
        colorName={ColorNameEnum.ultraDark}
        className="margin-top-20"
        align="left"
      />

      {/* secondary title */}
      <TextDiv titleText="Issued On:" value={currentDate} valueClassName="margin-left-5" />

      <div className="bordered" />

      {/* general info */}
      {eligibility?.memberName && eligibility?.dateOfBirth && (
        <>
          <TextDiv
            titleText={eligibility?.memberName}
            value={` / ${new Date(eligibility?.dateOfBirth).toLocaleString('en-US', {
              year: 'numeric',
              month: 'short',
              timeZone: 'UTC',
              day: '2-digit',
            })} / ${dayjs().diff(eligibility?.dateOfBirth, 'y')} yr`}
            valueClassName="margin-left-5 margin-top-2"
          />
        </>
      )}

      {insurer && (
        <img src={getInsurerLogoURL(insurer)} alt="insurer logo" style={{ width: '10.5rem' }} />
      )}
      <div className="bordered" />
      {/* member & eligibility details */}
      {sectionsToSelect.memberAndEligibility && (
        <>
          <MemberAndEligibilityDetails eligibility={eligibility} />
          <div className="bordered" />
        </>
      )}
      {/* in-network details */}
      {sectionsToSelect.inNetworkDetails && (
        <NetworkCard network={Network.InNetwork} networkType={inNetwork} />
      )}
      {/* out-of-network details */}
      {sectionsToSelect.outOfNetworkDetails && (
        <NetworkCard network={Network.OutOfNetwork} networkType={outOfNetwork} />
      )}
      <div className="pdf-footer margin-top-10">
        <Text
          size={SizeEnum['12px']}
          weight={WeightEnum.light}
          colorName={ColorNameEnum.ultraDark}
          align="left"
          className="margin-left-10"
        >
          The data provided through the Vim Connect application is sensitive and confidential. By
          using the data export functionallity. you acknowledge and agree that you will not share,
          distribute, or disseminate any of the exported data to any unauthorized third parties. You
          are solely responsible for the security and confidentiality of the exported data.
        </Text>
        <PoweredByVimFooter />
      </div>
    </>
  );
};

const MemberAndEligibilityDetails: React.FC<{ eligibility?: Partial<GetMemberResult> }> = ({
  eligibility,
}) => {
  const pcpCopay = eligibility?.coPay?.pcp?.inNetwork;
  const specialistCopay = eligibility?.coPay?.specialist?.inNetwork;

  return (
    <>
      <div className="pdf-icon-details margin-top-20 margin-bottom-10">
        <i className="icon-clipboard text-22" />
        <Text
          text="Member & Eligibility Details"
          size={SizeEnum['18px']}
          weight={WeightEnum.bold}
          colorName={ColorNameEnum.ultraDark}
          className="margin-left-10"
          align="left"
        />
      </div>

      <TextDiv titleText="Name:" value={eligibility?.memberName} valueClassName="margin-left-5" />

      <TextDiv
        titleText="Date of birth:"
        value={eligibility?.dateOfBirth ? formatDateString(eligibility?.dateOfBirth) : ''}
        valueClassName="margin-left-5"
      />

      {eligibility?.memberId && (
        <TextDiv
          titleText="Member ID:"
          value={eligibility?.memberId}
          valueClassName="margin-left-5"
        />
      )}
      {eligibility?.groupNumber && (
        <TextDiv
          titleText="Group number:"
          value={eligibility?.groupNumber}
          valueClassName="margin-left-5"
        />
      )}

      {eligibility?.plan && (
        <TextDiv titleText="Plan:" value={eligibility?.plan} valueClassName="margin-left-5" />
      )}
      {eligibility?.coverage && (
        <TextDiv
          titleText="Coverage:"
          value={eligibility?.coverage}
          valueClassName="margin-left-5"
        />
      )}
      {eligibility?.primaryProvider && (
        <TextDiv
          titleText="Primary care provider:"
          value={eligibility?.primaryProvider}
          valueClassName="margin-left-5"
        />
      )}
      <TextDiv
        titleText="Referral:"
        value={eligibility?.referral ? 'Required' : 'Not required'}
        valueClassName="margin-left-5"
      />
      {!pcpCopay && !specialistCopay ? null : (
        <TextDiv
          titleText="Primary care co-pay / Speclialist co-pay:"
          value={`${pcpCopay ? `$${pcpCopay}` : 'Not available'} / ${
            specialistCopay ? `$${specialistCopay}` : 'Not available'
          }`}
          valueClassName="margin-left-5"
        />
      )}
    </>
  );
};

const NetworkCard: React.FC<{
  network: Network;
  networkType: Deductible | undefined;
}> = ({ network, networkType }) => {
  if (!shouldShowNetworkCard(networkType)) return null;

  const { individualDeductible, individualOutOfPocket, familyDeductible, familyOutOfPocket } =
    extractDeductibleSections(networkType);

  const subSectionsDetails: { title: string; values?: DeductibleExpenditures }[] = [
    {
      title: 'Individual deductible:',
      values: individualDeductible,
    },
    {
      title: 'Family deductible:',
      values: familyDeductible,
    },
    {
      title: 'Individual out-of-pocket limit:',
      values: individualOutOfPocket,
    },
    {
      title: 'Individual family out-of-pocket limit:',
      values: familyOutOfPocket,
    },
  ];

  return (
    <>
      <div className="margin-top-20 margin-bottom-10" style={{ display: 'flex' }}>
        <div className="pdf-icon-details">
          <i
            className={`${
              network === Network.InNetwork ? 'icon-member-marked-shield' : 'icon-member-shield'
            } `}
          />
        </div>
        <Text
          text={network === Network.InNetwork ? 'In-Network Details' : 'Out-of-Network Details'}
          size={SizeEnum['18px']}
          weight={WeightEnum.bold}
          colorName={ColorNameEnum.ultraDark}
          className="margin-left-10"
          align="left"
        />
      </div>

      {subSectionsDetails.map(({ title, values }, index) => {
        if (!values || !shouldShowDeductibleSection(values)) return null;
        const { currentSpent, maximumAllowed } = values;
        const { valueLabel, maxValueLabel, percentageLabel } =
          calculateDeductibleDetails(currentSpent, maximumAllowed) ?? {};

        let text = `${valueLabel} of ${maxValueLabel} spent`;
        if (percentageLabel) text += ` ${percentageLabel}`;
        const idx = `${network}__pdf-template-sub-section__${index}`;

        return <TextDiv key={idx} titleText={title} value={text} valueClassName="margin-left-5" />;
      })}

      <div className="bordered" />
    </>
  );
};

const extractDeductibleSections = (deductibles: Deductible | undefined) => {
  const { individualNetworkDeductibleDetails, familyNetworkDeductibleDetails } = deductibles ?? {};

  const { deductible: individualDeductible, outOfPocket: individualOutOfPocket } =
    individualNetworkDeductibleDetails ?? {};
  const { deductible: familyDeductible, outOfPocket: familyOutOfPocket } =
    familyNetworkDeductibleDetails ?? {};

  return {
    individualDeductible,
    individualOutOfPocket,
    familyDeductible,
    familyOutOfPocket,
  };
};

const TextDiv: FC<{
  titleText: string;
  titleClassName?: string;
  value: any;
  valueClassName?: string;
}> = ({ titleText, value, titleClassName, valueClassName }) => (
  <div className="text-div">
    <Text
      text={titleText}
      size={SizeEnum['14px']}
      weight={WeightEnum.bold}
      colorName={ColorNameEnum.ultraDark}
      className={titleClassName}
      align="left"
    />
    <Text
      size={SizeEnum['14px']}
      weight={WeightEnum.medium}
      colorName={ColorNameEnum.ultraDark}
      className={valueClassName}
      align="left"
    >
      {value}
    </Text>
  </div>
);

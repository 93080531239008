import { ActivationStatus } from '@getvim/internal-vim-os-sdk/types';
import { useApi } from '@getvim/internal-vim-os-sdk/react';
import { useCallback } from 'react';
import { ModifyWidgetPayload } from '../../types';

const convertModifyPayloadToActivationStatus = ({
  loading,
  disabled,
}: ModifyWidgetPayload): ActivationStatus => {
  if (loading) return ActivationStatus.LOADING;
  if (!disabled) return ActivationStatus.ENABLED;
  return ActivationStatus.DISABLED;
};

export const useModifyWidget = (updateNotificationCount: boolean) => {
  const { setActivationStatus, setNotificationsIndicator, clearNotificationsIndicator } = useApi();

  return useCallback(async (payload: ModifyWidgetPayload) => {
    const status = convertModifyPayloadToActivationStatus(payload);
    setActivationStatus(status);

    if (updateNotificationCount) {
      const { notifications } = payload;
      if (status === ActivationStatus.ENABLED && notifications && notifications > 0) {
        setNotificationsIndicator(notifications);
      } else {
        clearNotificationsIndicator();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

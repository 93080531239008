import { useEffect, useState } from 'react';

const VIM_CONNECT_HUB_APP_OPENED = 'vim-connect-hub-app-opened';

export const useIsAppOpen = (widgetId: string) => {
  const [isAppOpen, setIsAppOpen] = useState<boolean>(false);

  useEffect(() => {
    const handleEvent = (e: {
      data: { type: string; payload: { selectedApplicationId: string | null } };
    }) => {
      if (e.data.type === VIM_CONNECT_HUB_APP_OPENED) {
        if (e.data.payload.selectedApplicationId === widgetId) {
          setIsAppOpen(true);
          return;
        }

        setIsAppOpen(false);
      }
    };

    window.addEventListener('message', handleEvent);
    return () => {
      window.removeEventListener('message', handleEvent);
    };
  }, []);

  return isAppOpen;
};

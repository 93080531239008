import { EligibilitySections } from '.';

export enum ExportAnalyticsEventNames {
  EXPORT_MENU_BUTTON_CLICKED = 'export_menu_button_clicked',
  EXPORT_MENU_INTERACTION = 'export_menu_interaction',
  EXPORT_MENU_ACTION_COMPLETED = 'export_menu_action_completed',
}

export interface ExportMenuButtonClickedPayload {
  interaction_type: 'opened' | 'closed';
  app_name: string;
  app_source_displayed?: string;
}

export interface ExportMenuInteractionPayload {
  sections_available: Array<keyof EligibilitySections>;
  section_selected?: Array<keyof EligibilitySections>;
  action_type: 'confirm' | 'cancel';
  export_option: ExportMenuOption;
  app_name: string;
  app_source_displayed?: string;
}

export interface ExportMenuActionCompletedPayload {
  status: 'successful' | 'failed' | 'cancelled';
  export_option: ExportMenuOption;
  app_name: string;
  app_source_displayed?: string;
}

export type ExportAnalyticsEvents = {
  [ExportAnalyticsEventNames.EXPORT_MENU_BUTTON_CLICKED]: ExportMenuButtonClickedPayload;
  [ExportAnalyticsEventNames.EXPORT_MENU_INTERACTION]: ExportMenuInteractionPayload;
  [ExportAnalyticsEventNames.EXPORT_MENU_ACTION_COMPLETED]: ExportMenuActionCompletedPayload;
};

export enum ExportMenuOption {
  DOWNLOAD = 'download',
}

export interface DownloadState {
  title: string;
  subtitle?: string;
  image: string;
  showCloseButton: boolean;
  timeout?: number;
}

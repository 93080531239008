import { useVimOsMigrationAPI } from '@getvim-core-apps/vim-os-migration-api';
import {
  AnalyticsHover,
  DetailsCardItem,
  SvgQuestionMarkIcon,
  ProgressBar,
  Tooltip,
} from '@getvim/atomic-ui';
import React, { FC } from 'react';
import { calculateDeductibleDetails, onHoverTooltip, shouldShowDeductibleSection } from './logic';
import { Network } from '..';
import { DeductibleExpenditures } from '../../../types';

import './DeductibleCardItem.less';

interface DeductibleCardItemProps {
  network: Network;
  itemTitle: string;
  deductibleSpent?: DeductibleExpenditures;
  informationText?: string;
}

export const DeductibleCardItem: FC<DeductibleCardItemProps> = ({
  network,
  itemTitle,
  deductibleSpent,
  informationText,
}) => {
  const {
    platformActions: { analytics },
  } = useVimOsMigrationAPI();

  const { currentSpent, maximumAllowed } = deductibleSpent ?? {};
  if (!shouldShowDeductibleSection(deductibleSpent)) return null;

  const { percentage, valueLabel, maxValueLabel, percentageLabel } =
    calculateDeductibleDetails(currentSpent, maximumAllowed) ?? {};

  const labelWithPercentage = percentageLabel ? `${valueLabel} ${percentageLabel}` : valueLabel;

  return (
    <div className="deductible-card">
      <>
        <DetailsCardItem id={`deductible-card__${network}__${itemTitle}`} label={itemTitle} />
        {informationText ? (
          <AnalyticsHover
            onHoverAction={() => {
              onHoverTooltip(analytics, itemTitle);
            }}
          >
            <Tooltip placement="top" tooltipContent={informationText} bgColor="light">
              <div className="deductible-card__question-mark">
                <SvgQuestionMarkIcon />
              </div>
            </Tooltip>
          </AnalyticsHover>
        ) : null}

        <ProgressBar
          value={percentage}
          valueLabel={labelWithPercentage}
          maxValueLabel={maxValueLabel}
          color="main"
        />
      </>
    </div>
  );
};

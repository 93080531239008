import React, { FC } from 'react';
import { ColorNameEnum, SizeEnum, Text, WeightEnum } from '@getvim/atomic-ui';

const NoDataAvailable: FC = () => (
  <Text
    text="No information available"
    size={SizeEnum['14px']}
    weight={WeightEnum.medium}
    colorName={ColorNameEnum.ultraDark}
    className="margin-top-30"
  />
);

export default NoDataAvailable;

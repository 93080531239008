import { logger } from '@getvim/vim-connect-logger';
import { useVimOsAPI } from './use-vim-os-api';
import { useRuntimeAPI } from './use-runtime-api';
import {
  VimOsMigrationAPI,
  VimOsMigrationAPIInput as VimOsMigrationAPIProviderProps,
} from './types';
import React, { createContext, useContext } from 'react';

const useVimOsLogger = logger.scope('useVimOsMigrationAPI');

export const isVimOS = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const isVimOs = queryParams.get('vim-os') === 'true';

  useVimOsLogger.info('Use Vim-OS api:', { isVimOs });
  return isVimOs;
};

const useVimOsMigrationApiInstance = isVimOS() ? useVimOsAPI : useRuntimeAPI;

const VimOsMigrationApiContext = createContext<VimOsMigrationAPI | undefined>(undefined);

export const VimOsMigrationAPIProvider: React.FC<VimOsMigrationAPIProviderProps> = ({
  children,
  ...props
}) => {
  const vimOsMigrationAPI = useVimOsMigrationApiInstance(props);

  return (
    <VimOsMigrationApiContext.Provider value={vimOsMigrationAPI}>
      {vimOsMigrationAPI ? children : null}
    </VimOsMigrationApiContext.Provider>
  );
};

export const useVimOsMigrationAPI: () => VimOsMigrationAPI = () =>
  useContext(VimOsMigrationApiContext)!;

import React from 'react';

function SvgQuestionMarkIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="20px"
      height="20px"
      viewBox="0 0 16 16"
    >
      <g id="web" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Authenticated-landing-page1" transform="translate(-889.000000, -504.000000)">
          <g id="Group-2" transform="translate(509.000000, 206.000000)">
            <g id="DocMinCard" transform="translate(26.000000, 60.000000)">
              <g id="Group">
                <g id="Group-5">
                  <g id="Group-3">
                    <g id="DoctorCard">
                      <g id="Group-4">
                        <g id="Group-8">
                          <g id="Group-6">
                            <g id="TextField-Copy-2" transform="translate(0.000000, 228.000000)">
                              <g
                                id="Text-field-1---Blank-Copy"
                                transform="translate(0.000000, 10.000000)"
                              >
                                <g id="Text-field-1---Selected" />
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
          <g id="menu-copy-4" transform="translate(889.000000, 504.000000)">
            <g id="Icon/question">
              <rect id="Rectangle" x={0} y={0} width={16} height={16} />
              <g id="question-circle" transform="translate(2.000000, 2.000000)" fill="#002E5A">
                <path
                  d="M6,0 C2.6914065,0 0,2.6914065 0,6 C0,9.308594 2.6914065,12 6,12 C9.308594,12 12,9.308594 12,6 C12,2.6914065 9.308594,0 6,0 Z M6,1 C8.767578,1 11,3.232422 11,6 C11,8.767578 8.767578,11 6,11 C3.232422,11 1,8.767578 1,6 C1,3.232422 3.232422,1 6,1 Z M6,3 C4.9003905,3 4,3.9003905 4,5 L5,5 C5,4.4414065 5.4414065,4 6,4 C6.558594,4 7,4.4414065 7,5 C7,5.3828125 6.7539065,5.7226565 6.390625,5.84375 L6.1875,5.90625 C5.779297,6.0410155 5.5,6.4316405 5.5,6.859375 L5.5,7.5 L6.5,7.5 L6.5,6.859375 L6.703125,6.796875 C7.4726565,6.5410155 8,5.810547 8,5 C8,3.9003905 7.0996095,3 6,3 Z M5.5,8 L5.5,9 L6.5,9 L6.5,8 L5.5,8 Z"
                  id="Shape"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgQuestionMarkIcon;

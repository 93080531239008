import { useApi, useAppState, useVimAuth } from '@getvim/internal-vim-os-sdk/react';
import { useCallback, useEffect, useMemo } from 'react';
import {
  useEhrState,
  useEhrUpdatableEntities,
  useLazyConfig,
  useModifyWidget,
  useRunAction,
  useWriteback,
} from './converters';
import { useAppUserEffect } from './metadata';
import { VimOsMigrationAPI, VimOsMigrationAPIInput } from '../types';

export const useVimOsAPI = ({
  setAccessToken,
  updateNotificationCount,
}: VimOsMigrationAPIInput): VimOsMigrationAPI | undefined => {
  const ehrState = useEhrState();
  const { trackAnalytics, autoPopup, setDynamicAppSize } = useApi();
  const ehrUpdatableEntities = useEhrUpdatableEntities();
  const updateEncounter = useWriteback();
  const modifyWidget = useModifyWidget(updateNotificationCount);
  const runAction = useRunAction();
  const { isAppOpen } = useAppState();
  const { accessToken } = useVimAuth();
  const [getAppConfig, config] = useLazyConfig();
  useAppUserEffect();

  useEffect(() => {
    if (!accessToken) {
      return;
    }
    setAccessToken(accessToken);

    if (!config) {
      getAppConfig();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken]);

  const analytics = useMemo(
    () => ({
      track: (event: string, properties: any) =>
        trackAnalytics({ event, properties: { ...properties, is_vim_os_app: true } }),
    }),
    [trackAnalytics],
  );

  const selectApp = useCallback(async () => {
    autoPopup();
  }, [autoPopup]);

  return useMemo(() => {
    if (!config) return;

    return {
      ehrState,
      ehrEntities: {
        updatableEntities: ehrUpdatableEntities,
        entitiesActions: {
          updateEncounter,
        },
      },
      platformActions: {
        modifyWidget,
        resizeWidget: setDynamicAppSize,
        selectApp,
        runAction,
        isAppOpen: !!isAppOpen,
        analytics,
      },
      config,
      isVimOs: true,
    };
  }, [
    config,
    ehrState,
    isAppOpen,
    modifyWidget,
    setDynamicAppSize,
    selectApp,
    runAction,
    analytics,
    ehrUpdatableEntities,
    updateEncounter,
  ]);
};

import React, { FC } from 'react';
import { DetailsCardItem, TextTransformEnum } from '@getvim/atomic-ui';

export interface EligibilityCardItemProps {
  id?: string;
  label: string;
  value?: string;
  valueTextTransform?: TextTransformEnum;
}

export const EligibilityCardItem: FC<EligibilityCardItemProps> = ({
  id,
  label,
  value,
  valueTextTransform,
}) => (
  <DetailsCardItem
    id={id}
    label={label}
    value={value || '--'}
    valueTextTransform={valueTextTransform}
  />
);

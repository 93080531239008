import { ApplicationSize } from '@getvim-os/types';
import { HubOutgoingEvents, useSendHubEvents } from '@getvim/react-app-infra';
import { APP_SIZE_MAP, ModifyWidgetPayload, VimOsMigrationAPI } from '../../types';
import { ActionNames } from '@getvim/internal-vim-os-sdk/types';
import { useCallback } from 'react';

type UseSendEventToHub = Pick<
  VimOsMigrationAPI['platformActions'],
  'modifyWidget' | 'resizeWidget' | 'selectApp' | 'runAction'
>;

export const useSendEventToHub = (widgetId: string): UseSendEventToHub => {
  const { sendEventToHub } = useSendHubEvents(widgetId);

  const modifyWidget = useCallback(
    async (payload: ModifyWidgetPayload) =>
      await sendEventToHub(HubOutgoingEvents.ModifyWidget, payload),
    [sendEventToHub],
  );

  const resizeWidget = useCallback(
    async (appSize: ApplicationSize) =>
      await sendEventToHub(HubOutgoingEvents.ModifyWidget, {
        size: APP_SIZE_MAP[appSize],
      }),
    [sendEventToHub],
  );

  const selectApp = useCallback(
    async () => await sendEventToHub(HubOutgoingEvents.SelectAppInHub, undefined),
    [sendEventToHub],
  );

  const runAction = useCallback(
    async (actionType: ActionNames, payload?: any) => {
      return await sendEventToHub(HubOutgoingEvents.RunAction, {
        type: actionType,
        payload,
      });
    },
    [sendEventToHub],
  );

  return { modifyWidget, resizeWidget, selectApp, runAction };
};

import { useConfig } from '@getvim/react-app-infra';
import { useMemo } from 'react';
import { ConfigUnified } from '../../types';

export const useRuntimeConfig = (): ConfigUnified => {
  const { organizationConfig, vimConnectUser, productConfig } = useConfig();

  return useMemo(() => {
    return {
      adapterName: organizationConfig.ehrSystem,
      organization: {
        id: organizationConfig.id,
        name: organizationConfig.name,
        theme: organizationConfig.theme,
      },
      user: {
        email: vimConnectUser?.email,
      },
      contentSources: productConfig.contentSources ?? [],
    };
  }, [organizationConfig, vimConnectUser, productConfig]);
};

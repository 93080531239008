import { vimConnectClientLogger } from '@getvim/react-app-infra';
import { ScopedLogger, Team } from '@getvim/vim-connect-logger';
import { widgetId } from '../consts';

export const eligibilityLogger: ScopedLogger = vimConnectClientLogger.scope(
  'Eligibility Client',
  {
    moduleName: widgetId,
    moduleAndScopeSameColor: true,
  },
  {
    team: Team.PatientInsights,
    moduleName: widgetId,
  },
);

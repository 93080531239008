import { useVimUserMetadata } from '@getvim/internal-vim-os-sdk/react';
import { logger } from '@getvim/vim-connect-logger';
import { useEffect } from 'react';

const UNKNOWN = 'unknown';
const userMetadataLogger = logger.scope('Migration API - useAppUserMetadata');

export const useAppUserEffect = (): void => {
  const vimUser = useVimUserMetadata();

  useEffect(() => {
    if (!vimUser) {
      return;
    }

    const { ehrVendor, deviceId, organization, user } = vimUser;
    const { id, name } = organization?.identifiers ?? {};
    const { ehrUsername: ehrUserName } = user?.identifiers ?? {};

    const metadata = {
      deviceId: deviceId ?? UNKNOWN,
      windowId: UNKNOWN,
      version: globalThis?.$vim_environment?.version,
      adapterName: ehrVendor,
      organizationId: id,
      organization_name: name,
      ehrUserName,
      isVimOsApplication: true,
    };

    logger.setMetadata(metadata);
    userMetadataLogger.info('Vim-OS Application Metadata', { metadata });
  }, [vimUser]);
};

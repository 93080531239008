import { ApplicationSize, Order } from '@getvim-os/types';
import { VimEhrStateProps } from '@getvim/react-app-infra';
import { AnalyticsClient } from '@getvim/utils-vim-connect-communication';
import { ConfigUnified } from './config';
import { EhrEntitiesActions, EhrUpdatableEntities } from './ehr';
import { ActionNames } from '@getvim/internal-vim-os-sdk/types';

export interface VimOsMigrationAPIInput {
  widgetId: string;
  setAccessToken: (accessToken: string) => void;
  runtimeAnalyticsClient: AnalyticsClient<any>;
  updateNotificationCount: boolean;
}

export type RuntimeEhrState = VimEhrStateProps & { orders?: Order[] };

export interface VimOsMigrationAPI {
  ehrState: RuntimeEhrState;
  ehrEntities: {
    updatableEntities: EhrUpdatableEntities;
    entitiesActions: EhrEntitiesActions;
  };
  platformActions: {
    modifyWidget: (payload: ModifyWidgetPayload) => Promise<void>;
    resizeWidget: (size: ApplicationSize) => void;
    selectApp: () => Promise<void>;
    runAction: RunActionCallback;
    isAppOpen: boolean;
    analytics: {
      track: AnalyticsClientUnified['track'];
    };
  };
  config: ConfigUnified;
  isVimOs: boolean;
}

export type ModifyWidgetPayload = {
  notifications?: number;
  disabled?: boolean;
  loading?: boolean;
  loaded?: boolean;
  reason?: string;
};

interface AnalyticsClientUnified {
  track: (event: string, properties: any) => void;
}

export type RunActionCallback = (
  actionType: ActionNames,
  payload?: any,
) => Promise<RunActionResult>;

export type RunActionResult = { data: any; error?: any };

export const APP_SIZE_MAP = {
  [ApplicationSize.CLASSIC]: { width: '365px', height: '90vh' },
  [ApplicationSize.LARGE]: { width: '800px', height: '90vh' },
  [ApplicationSize.EXTRA_LARGE]: { width: '1350px', height: '90vh' },
};

import { VimConnectGqlClient } from '@getvim/components-vim-connect-gql-client';

import { eligibilityLogger } from '../utils/logger';

export class GraphqlClient {
  private static accessToken: string;

  private static requestNpi: string | undefined;

  static setAccessToken(accessToken) {
    GraphqlClient.accessToken = accessToken;
  }

  static setRequestNpi(requestNpi: string | undefined) {
    GraphqlClient.requestNpi = requestNpi;
  }

  static eligibilityBFFClient = new VimConnectGqlClient({
    accessTokenCallback: () => GraphqlClient.accessToken,
    logger: eligibilityLogger,
  });
}

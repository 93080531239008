import { Insurer } from '@getvim-os/standard';
import { logger } from '@getvim/vim-connect-logger';

const logosLogger = logger.scope('getInsurerLogoURL');

const logosBaseURL = 'https://static.getvim.com/insurers-logos';

const insurerLogos = {
  [Insurer.ANTHEM]: 'anthem.svg',
  [Insurer.CIGNA]: 'cigna_2.svg',
  [Insurer.FLORIDA_BLUE]: 'florida-blue.svg',
  [Insurer.PREMERA]: 'premera.svg',
  [Insurer.UHC]: 'united-healthcare.svg',
};

const getInsurerLogoURL = (insurer: Insurer): string | undefined => {
  if (insurerLogos[insurer]) {
    const logoURL = `${logosBaseURL}/${insurerLogos[insurer]}`;
    logosLogger.info(`returning logo url of ${insurer}`, { insurer, logoURL });
    return logoURL;
  }
  logosLogger.warning(`cannot find logo for ${insurer}`, { insurer });
  return undefined;
};

export default getInsurerLogoURL;

import React, { FC, useEffect, useState } from 'react';
import { useVimOsMigrationAPI } from '@getvim-core-apps/vim-os-migration-api';
import { themes, ThemeVariablesWrapper } from '@getvim/components-hooks-use-theme';
import EligibilityContainer from './atomic/EligibilityContainer';
import { GlobalContext, GlobalContextProps } from './context';

import '@getvim/atomic-ui/assets/styles/main.less';
import './App.less';

const App: FC = () => {
  const {
    config,
    ehrState: { patient },
    platformActions: { modifyWidget },
  } = useVimOsMigrationAPI();

  const [globalContext, setGlobalContext] = useState<GlobalContextProps>({
    featureFlags: {},
  });

  useEffect(() => {
    setGlobalContext((context) => ({
      ...context,
      featureFlags: {},
    }));
  }, []);

  useEffect(() => {
    if (!patient) {
      modifyWidget({
        notifications: 0,
        disabled: true,
        loading: false,
      });
    }
  }, [patient, modifyWidget]);

  return (
    <GlobalContext.Provider value={globalContext}>
      <ThemeVariablesWrapper theme={themes[config.organization.theme] || themes.vimConnect}>
        {patient ? <EligibilityContainer patient={patient} /> : null}
      </ThemeVariablesWrapper>
    </GlobalContext.Provider>
  );
};

export default App;
